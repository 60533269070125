var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":""},scopedSlots:_vm._u([{key:"code",fn:function(){return [_vm._v(" "+_vm._s(_vm.codeMultiple)+" ")]},proxy:true}])},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"name"}},[_vm._v("Name")]),_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","name":"name","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.createdDevice.name),callback:function ($$v) {_vm.$set(_vm.createdDevice, "name", $$v)},expression:"createdDevice.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"model_name"}},[_vm._v("Model name")]),_c('validation-provider',{attrs:{"name":"Model name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"model_name","name":"name","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.createdDevice.model_name),callback:function ($$v) {_vm.$set(_vm.createdDevice, "model_name", $$v)},expression:"createdDevice.model_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"sku_id"}},[_vm._v("Sku Id")]),_c('validation-provider',{attrs:{"name":"sku_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"sku_id","name":"sku_id","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.createdDevice.sku_id),callback:function ($$v) {_vm.$set(_vm.createdDevice, "sku_id", $$v)},expression:"createdDevice.sku_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"category_id "}},[_vm._v("Category id ")]),_c('validation-provider',{attrs:{"name":"Category id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","name":"category_id","type":"number","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.createdDevice.category_id),callback:function ($$v) {_vm.$set(_vm.createdDevice, "category_id", $$v)},expression:"createdDevice.category_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"sub_category_id"}},[_vm._v("Sub category id ")]),_c('validation-provider',{attrs:{"name":"Sub Category Id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","name":"sub_category_id","type":"number","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.createdDevice.sub_category_id),callback:function ($$v) {_vm.$set(_vm.createdDevice, "sub_category_id", $$v)},expression:"createdDevice.sub_category_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('div',{staticClass:"mt-2 mb-2",staticStyle:{"display":"flex"}},[_c('label',{staticClass:"mr-1",attrs:{"for":"is_oil_consumable"}},[_vm._v("Oil Consumable")]),_c('validation-provider',{attrs:{"name":"Oil Consumable","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"custom-control-secondary",attrs:{"id":"is_oil_consumable","name":"is_oil_consumable","switch":""},model:{value:(_vm.createdDevice.is_oil_consumable),callback:function ($$v) {_vm.$set(_vm.createdDevice, "is_oil_consumable", $$v)},expression:"createdDevice.is_oil_consumable"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('div',{staticClass:"mt-2 mb-2",staticStyle:{"display":"flex"}},[_c('label',{staticClass:"mr-1",attrs:{"for":"is_wireless"}},[_vm._v("Device Connectable wireless")]),_c('validation-provider',{attrs:{"name":"Device Connectable wireless","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"custom-control-secondary",attrs:{"id":"is_wireless","name":"is_wireless","switch":""},model:{value:(_vm.createdDevice.is_wireless),callback:function ($$v) {_vm.$set(_vm.createdDevice, "is_wireless", $$v)},expression:"createdDevice.is_wireless"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1)],1),_c('b-row',[_c('h4',{staticClass:"card-title ml-1"},[_vm._v("Add Setting to the device")])]),_c('form-repeater-basic',{attrs:{"items":[]},on:{"deviceSettings":function($event){return _vm.catchDeviceSettings($event)}}}),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"customizable","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"customizable","name":"customizable","value":"1","unchecked-value":"0"},model:{value:(_vm.createdDevice.customizable),callback:function ($$v) {_vm.$set(_vm.createdDevice, "customizable", $$v)},expression:"createdDevice.customizable"}},[_vm._v(" Customizable ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),(_vm.customizable)?_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"start_range "}},[_vm._v("Start Range")]),_c('validation-provider',{attrs:{"name":"Start range","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","name":"start_range","type":"number","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.createdDevice.start_range),callback:function ($$v) {_vm.$set(_vm.createdDevice, "start_range", $$v)},expression:"createdDevice.start_range"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2047282725)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"end_range"}},[_vm._v("End range")]),_c('validation-provider',{attrs:{"name":"End range","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","name":"end_range","type":"number","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.createdDevice.end_range),callback:function ($$v) {_vm.$set(_vm.createdDevice, "end_range", $$v)},expression:"createdDevice.end_range"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1452324133)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"grade_level"}},[_vm._v("Grade level")]),_c('validation-provider',{attrs:{"name":"Grade range","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","name":"grade_level","type":"number","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.createdDevice.grade_level),callback:function ($$v) {_vm.$set(_vm.createdDevice, "grade_level", $$v)},expression:"createdDevice.grade_level"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4243202853)})],1)],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"image"}},[_vm._v("Image")]),_c('validation-provider',{attrs:{"name":"Image","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-dropzone',{ref:"deviceImageRef",attrs:{"id":"image","name":"image","options":_vm.dropzoneOptions}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Submit ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }