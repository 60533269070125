<template>
  <div>
    <Spinner v-if="loading === true" />
    <div
      v-if="loading === false"
      class="row"
    >
      <div class="col-md-6" />
      <div class="col-md-6 create-button">
        <button
          id="deleteMultiple"
          type="button"
          class="btn btn-outline-primary mb-2"
          @click="confirmDeleteText(deletedArry)"
        >
          Delete Selected Elements
        </button>
        <button
          type="button"
          class="btn btn-primary mb-2 ml-1"
          @click="navigateTo('create-device')"
        >
          Create
        </button>
      </div>
    </div>
    <basic-table
      v-if="loading === false"
      ref="table"
      :columns="columns"
      :values="rows"
      :editable-page="false"
      :actions-obj="actionsArray"
      @dynamicAction="handleActions($event)"
      @choosenObject="getEditedObject($event)"
      @deleteItems="removeItems($event)"
      @deletedMultipleIds="catchIds($event)"
      @changeStatus="handleChangeStatus($event)"
    />
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import Spinner from '@/shared/loader/Spinner'
import devicesService from '@/services/devicesService'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import BasicTable from '../../../shared/tables/BasicTable.vue'
import Create from './create.vue'
import Edit from './edit.vue'

export default {
  components: {
    Spinner,
    BRow,
    BCol,
    Create,
    Edit,
    BasicTable,
    ToastificationContent,
  },
  data() {
    return {
      loading: false,
      status: { is_active: '' },
      UpdatedDevice: {
        is_active: '',
      },
      userId: '',
      actionsArray: [],
      editedTest: '',
      roleId: '',
      columns: [
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'display_name',
          field: 'displayName',
        },
        {
          label: 'Model name',
          field: 'modelName',
        },
        {
          label: 'SKU',
          field: 'skuId',
        },
        {
          label: 'Image',
          field: 'image',
        },
        {
          label: 'Status',
          field: 'is_active',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  created() {
    this.getDevices()
    this.actionsArray = [{ title: 'Edit', icon: 'EditIcon', conditionalColumn: '' }]
  },
  methods: {
    getDevices() {
      this.loading = true
      devicesService.index().then(response => {
        this.rows = response.data.data
        this.loading = false
      })
    },
    handleActions(itemObj) {
      switch (itemObj.key) {
        case 'Edit':
          this.$router.push({ name: 'edit-device', params: { id: itemObj.id } })
          break
        default:
          break
      }
    },
    getEditedObject(obj) {
      this.editedTest = obj
    },
    catchIds(ids) {
      this.deletedArry = ids
    },
    removeItems(arr) {
      this.loading = true
      this.confirmDeleteText(arr)
    },
    confirmDeleteText(arr) {
      if (arr.length > 0) {
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.loading = false
            devicesService.delete(arr.join()).then(response => {
              if (response && response.status === 200) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Your Items have been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                }).then(() => {
                  this.renderTable()
                })
              }
            }).catch(error => {
              console.log('ddd')
              this.loading = false
              return Promise.reject(error)
            })
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: '',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.loading = false
          } else {
            this.loading = false
          }
        })
      }
    },
    handleChangeStatus(row) {
      if (row) {
        this.$swal({
          title: 'Are you sure of publishing this Device?',
          text: ' ',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, publish it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.status.is_active = row.item.is_active === 0 ? 1 : 0
            // eslint-disable-next-line no-unused-expressions
            this.UpdatedDevice = {
              is_active: this.status.is_active,
            },

            devicesService.changeStatus(row.item.id, this.UpdatedDevice, { lang: 'en' })
              .then(response => {
                /* Reset Inputs */
                if (response && response.data.code === 200) {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Success',
                      icon: 'CheckIcon',
                      variant: 'success',
                      text: 'Device has been published successfully !',
                    },
                  })
                  this.renderTable()
                }else{
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Error',
                      icon: 'CheckIcon',
                      variant: 'danger',
                      text: response.data.message,
                    },
                  })
                  this.renderTable()
                }
                /* Hide Spinner */
                this.loading = false
              })
              .catch(
                function (error) {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Error',
                      icon: 'CheckIcon',
                      variant: 'Error',
                      text: error.message,
                    },
                  })
                  // return Promise.reject(error)
                },
              )
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: '',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.loading = false
          } else {
            this.loading = false
          }
        })
      }
    },
    renderTable() {
      this.getDevices()
    },
    navigateTo(path) {
      this.$router.push({ name: `${path}` })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
