<script
    src="../../../../../../../AppData/Local/Temp/Rar$DRa14036.14120/src/app/helper/alertTimer/alert-timer.service.ts"
></script>
<template>
  <b-card-code title="Edit">
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <label for="name">Name</label>
              <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
              >
                <b-form-input
                    id="name"
                    v-model="editedDevice.name"
                    name="name"
                    :state="errors.length > 0 ? false:null"
                    placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label for="model_name">Model name</label>
              <validation-provider
                  #default="{ errors }"
                  name="Model name"
                  rules="required"
              >
                <b-form-input
                    id="name"
                    v-model="editedDevice.model_name"
                    name="name"
                    :state="errors.length > 0 ? false:null"
                    placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label for="sku_id">Sku Id</label>
              <validation-provider
                  #default="{ errors }"
                  name="sku_id"
                  rules="required"
              >
                <b-form-input
                    id="sku_id"
                    v-model="editedDevice.sku_id"
                    name="sku_id"
                    :state="errors.length > 0 ? false:null"
                    placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label for="category_id ">Category id </label>
              <validation-provider
                  #default="{ errors }"
                  name="Category id"
                  rules="required"
              >
                <b-form-input
                    id="name"
                    v-model="editedDevice.category_id"
                    name="category_id"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                    placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label for="sub_category_id">Sub category id </label>
              <validation-provider
                  #default="{ errors }"
                  name="Sub Category Id"
                  rules="required"
              >
                <b-form-input
                    id="name"
                    v-model="editedDevice.sub_category_id"
                    name="sub_category_id"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                    placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <div
                  class="mt-2 mb-2"
                  style="display: flex"
              >
                <label
                    for="is_oil_consumable"
                    class="mr-1"
                >Oil Consumable</label>
                <validation-provider
                    #default="{ errors }"
                    name="Oil Consumable"
                    rules="required"
                >
                  <b-form-checkbox
                      id="is_oil_consumable"
                      name="is_oil_consumable"
                      class="custom-control-secondary"
                      switch
                      :checked="editedDevice.is_oil_consumable === 0 ? false : true"
                      @change="catchIsOilConsumable($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <div
                  class="mt-2 mb-2"
                  style="display: flex"
              >
                <label
                    for="is_device_connectable"
                    class="mr-1"
                >Device Connectable wireless</label>
                <validation-provider
                    #default="{ errors }"
                    name="Device Connectable wireless"
                    rules="required"
                >
                  <b-form-checkbox
                      id="is_device_connectable"
                      name="is_wireless"
                      class="custom-control-secondary"
                      switch
                      :checked="editedDevice.is_wireless === 0 ? false : true"
                      @change="catchWireless($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <h4 class="card-title ml-1">
            Add Setting to the device
          </h4>
        </b-row>
        <form-repeater-basic
            v-if="editedDevice.settings"
            :items="deviceSettings"
            @deviceSettings="catchDeviceSettings($event)"
        />
        <b-row>
          <b-col md="12">
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  name="customizable"
                  rules="required"
              >
                <b-form-checkbox
                    id="customizable"
                    v-model="editedDevice.customizable"
                    name="customizable"
                    value="1"
                    unchecked-value="0"
                >
                  Customizable
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="customizable">
          <b-col md="4">
            <b-form-group>
              <label for="start_range ">Start Range</label>
              <validation-provider
                  #default="{ errors }"
                  name="Start range"
                  rules="required"
              >
                <b-form-input
                    id="name"
                    v-model="editedDevice.start_range"
                    name="start_range"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                    placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label for="end_range">End range</label>
              <validation-provider
                  #default="{ errors }"
                  name="End range"
                  rules="required"
              >
                <b-form-input
                    id="name"
                    v-model="editedDevice.end_range"
                    name="end_range"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                    placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label for="grade_level">Grade level</label>
              <validation-provider
                  #default="{ errors }"
                  name="Grade range"
                  rules="required"
              >
                <b-form-input
                    id="name"
                    v-model="editedDevice.grade_level"
                    name="grade_level"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                    placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group>
              <label for="image">Image</label>
              <validation-provider
                  #default="{ errors }"
                  name="Image"
                  rules=""
              >
                <vue-dropzone
                    id="image"
                    ref="deviceImageRef"
                    name="image"
                    :options="dropzoneOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="modal-footer">
          <button
              type="button"
              class="btn btn-primary"
              @click.prevent="validationForm"
          >
            Submit
          </button>
        </div>
      </b-form>
    </validation-observer>
    <template #code>
      {{ codeMultiple }}
    </template>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BFormCheckbox
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import vSelect from 'vue-select'
import vue2Dropzone from 'vue2-dropzone'
import {codeMultiple, codeBasic} from './code'
import FormTextAreaDefault from '../../../shared/form-textarea/FormTextAreaDefault.vue'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import devicesService from '../../../services/devicesService'
import FormRepeaterBasic from './FormRepeaterBasic.vue'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BFormCheckbox,
    FormTextAreaDefault,
    vSelect,
    vueDropzone: vue2Dropzone,
    FormRepeaterBasic
  },
  data() {
    return {
      required,
      codeMultiple,
      customizable: false,
      deviceSettings: [],
      editedDevice: {
        sku_id: '',
        name: '',
        model_name: '',
        category_id: '',
        sub_category_id: '',
        customizable: 0,
        start_range: '',
        end_range: '',
        grade_level: '',
        settings: [],
        is_wireless: 0
      },
      codeBasic,
      snowOption: {
        theme: 'snow',
      },
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        addRemoveLinks: true,
        acceptedFiles: '.jpeg,.jpg,.png,.gif',
        dictRemoveFile: 'Remove',
        maxFiles: 1,
        init: function () {
          this.on("maxfilesexceeded", function (file) {
            this.removeAllFiles();
            this.addFile(file);
          });
        },
      },
    }
  },
  mounted() {
    this.getDevice()
    this.$watch(() => this.editedDevice.customizable, val => {
      if (val === '1')
        this.customizable = true
      else
        this.customizable = false
    })
    this.$watch(() =>  this.editedDevice, val => {
      this.deviceSettings = val.settings
      this.$refs.deviceImageRef.removeAllFiles()
      this.$refs.deviceImageRef.manuallyAddFile(val.image[0].file, val.image[0].url)

      if (val.customizable === 1 || val.customizable === '1')
        this.customizable = true
      else
        this.customizable = false
    })
  },
  methods: {
    getDevice() {
      this.loading = true
      devicesService.show(this.$route.params.id).then(response => {
        this.editedDevice = response.data.data
        this.editedDevice.sku_id = response.data.data.skuId
        this.editedDevice.is_wireless = response.data.data.isWireless
        this.editedDevice.is_oil_consumable = response.data.data.isOilConsumable
        this.editedDevice.start_range = response.data.data.startRange
        this.editedDevice.end_range = response.data.data.endRange
        this.editedDevice.grade_level = response.data.data.gradeLevel
        this.editedDevice.category_id = response.data.data.categoryId
        this.editedDevice.sub_category_id = response.data.data.subCategoryId

        this.loading = false
      })
    },
    validationForm() {
      this.$refs.simpleRules.validate()
          .then(success => {
            if (success) {
              this.editedDevice.image = this.$refs.deviceImageRef.getAcceptedFiles()[0]

              devicesService.put(this.$route.params.id, this.editedDevice).then(response => {
                if (response && response.data.code === 200) {
                  this.$emit('refresh', 'true')
                  this.$router.push({
                    name: 'devices',
                    // params: { id: itemObj.id },
                  })

                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Success',
                      icon: 'CheckIcon',
                      variant: 'success',
                      text: 'Device Is Updated Successfully !',
                    },
                  })
                }
                else{
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Error',
                      icon: 'CheckIcon',
                      variant: 'danger',
                      text: response.data.message,
                    },
                  })
                }
                this.loading = false
              })

            }
          }).catch(function (error) {

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Success',
            icon: 'CheckIcon',
            variant: 'Error',
            text: 'Error !',
          },
        })
        return Promise.reject(error)
      })
    },
    catchDeviceSettings(settings) {
      this.editedDevice.settings = settings
      this.editedDevice.settings = settings

    },
    catchWireless(wirlessBool){
      this.editedDevice.is_wireless = wirlessBool
    },
    catchIsOilConsumable(isOilConsumable){
      this.editedDevice.is_oil_consumable = isOilConsumable
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

label {
  font-weight: bold;
}

#createModal svg {
  filter: invert(69%) sepia(14%) saturate(4763%) hue-rotate(3deg) brightness(100%) contrast(43%) !important;
}
</style>
