var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":""},scopedSlots:_vm._u([{key:"code",fn:function(){return [_vm._v(" "+_vm._s(_vm.codeBasic)+" ")]},proxy:true}])},[_c('div',[_c('b-form',{ref:"form",staticClass:"repeater-form",on:{"submit":function($event){$event.preventDefault();return _vm.repeateAgain.apply(null, arguments)}}},_vm._l((_vm.createdDeviceSettings),function(item,index){return _c('div',{key:item.id,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"capacity"}},[_vm._v("Capacity")]),_c('validation-provider',{attrs:{"name":"capacity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"capacity","name":"capacity","type":"text","state":errors.length > 0 ? false:null,"placeholder":""},on:{"change":function($event){return _vm.updateSettings()}},model:{value:(_vm.createdDeviceSettings[index].capacity),callback:function ($$v) {_vm.$set(_vm.createdDeviceSettings[index], "capacity", $$v)},expression:"createdDeviceSettings[index].capacity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"intensity_level"}},[_vm._v("Intensity Level")]),_c('validation-provider',{attrs:{"name":"intensity_level","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"intensity_level","type":"number","name":"intensity_level","state":errors.length > 0 ? false:null,"placeholder":""},on:{"change":function($event){return _vm.updateSettings()}},model:{value:(_vm.createdDeviceSettings[index].intensity_level),callback:function ($$v) {_vm.$set(_vm.createdDeviceSettings[index], "intensity_level", $$v)},expression:"createdDeviceSettings[index].intensity_level"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"work"}},[_vm._v("Work hours")]),_c('validation-provider',{attrs:{"name":"work","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"work","type":"number","name":"work","state":errors.length > 0 ? false:null,"placeholder":""},on:{"change":function($event){return _vm.updateSettings()}},model:{value:(_vm.createdDeviceSettings[index].work),callback:function ($$v) {_vm.$set(_vm.createdDeviceSettings[index], "work", $$v)},expression:"createdDeviceSettings[index].work"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"stop"}},[_vm._v("Stop hour")]),_c('validation-provider',{attrs:{"name":"stop","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"stop","type":"number","name":"stop","state":errors.length > 0 ? false:null,"placeholder":""},on:{"change":function($event){return _vm.updateSettings()}},model:{value:(_vm.createdDeviceSettings[index].stop),callback:function ($$v) {_vm.$set(_vm.createdDeviceSettings[index], "stop", $$v)},expression:"createdDeviceSettings[index].stop"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"oil_consumption"}},[_vm._v("Oil Consumption")]),_c('validation-provider',{attrs:{"name":"oil_consumption","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"oil_consumption","type":"number","name":"oil_consumption","state":errors.length > 0 ? false:null,"placeholder":""},on:{"change":function($event){return _vm.updateSettings()}},model:{value:(_vm.createdDeviceSettings[index].oil_consumption),callback:function ($$v) {_vm.$set(_vm.createdDeviceSettings[index], "oil_consumption", $$v)},expression:"createdDeviceSettings[index].oil_consumption"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-col',{staticClass:"mb-50",attrs:{"lg":"2","md":"3"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0 mt-md-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v("Delete")])],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])],1)}),0)],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":_vm.repeateAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Add New")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }