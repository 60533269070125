<template>
  <b-card-code title="">
    <div>
      <b-form
        ref="form"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >

        <!-- Row Loop -->
        <div
          v-for="(item, index) in createdDeviceSettings"
          :id="item.id"
          :key="item.id"
          ref="row"
        >

          <b-row>
            <b-col md="4">
              <b-form-group>
                <label for="capacity">Capacity</label>
                <validation-provider
                  #default="{ errors }"
                  name="capacity"
                  rules="required"
                >
                  <b-form-input
                    id="capacity"
                    v-model="createdDeviceSettings[index].capacity"
                    name="capacity"
                    type="text"
                    :state="errors.length > 0 ? false:null"
                    placeholder=""
                    @change="updateSettings()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label for="intensity_level">Intensity Level</label>
                <validation-provider
                  #default="{ errors }"
                  name="intensity_level"
                  rules="required"
                >
                  <b-form-input
                    id="intensity_level"
                    v-model="createdDeviceSettings[index].intensity_level"
                    type="number"
                    name="intensity_level"
                    :state="errors.length > 0 ? false:null"
                    placeholder=""
                    @change="updateSettings()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label for="work">Work hours</label>
                <validation-provider
                  #default="{ errors }"
                  name="work"
                  rules="required"
                >
                  <b-form-input
                    id="work"
                    v-model="createdDeviceSettings[index].work"
                    type="number"
                    name="work"
                    :state="errors.length > 0 ? false:null"
                    placeholder=""
                    @change="updateSettings()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <label for="stop">Stop hour</label>
                <validation-provider
                  #default="{ errors }"
                  name="stop"
                  rules="required"
                >
                  <b-form-input
                    id="stop"
                    v-model="createdDeviceSettings[index].stop"
                    type="number"
                    name="stop"
                    :state="errors.length > 0 ? false:null"
                    placeholder=""
                    @change="updateSettings()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label for="oil_consumption">Oil Consumption</label>
                <validation-provider
                  #default="{ errors }"
                  name="oil_consumption"
                  rules="required"
                >
                  <b-form-input
                    id="oil_consumption"
                    v-model="createdDeviceSettings[index].oil_consumption"
                    type="number"
                    name="oil_consumption"
                    :state="errors.length > 0 ? false:null"
                    placeholder=""
                    @change="updateSettings()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Remove Button -->
          <b-col
            lg="2"
            md="3"
            class="mb-50"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2"
              @click="removeItem(index)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span>Delete</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </div>

      </b-form>
    </div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="repeateAgain"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>Add New</span>
    </b-button>

    <template #code>
      {{ codeBasic }}
    </template>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { codeBasic } from './code'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: ['items'],
  data() {
    return {
      createdDeviceSettings: [
        {
          capacity: '',
          intensity_level: '',
          work: '',
          stop: '',
          oil_consumption: '',
        },
      ],
      nextTodoId: 2,
      codeBasic,
    }
  },
  mounted() {
    this.initTrHeight()

    this.$watch(() => this.items, items => {
      // this.items = items
      this.createdDeviceSettings = items
      console.log(this.items)

      // this.$emit('deviceSettings', deviceSettings)
    })
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeateAgain() {
      this.createdDeviceSettings.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.createdDeviceSettings.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    updateSettings() {
      console.log('child', this.createdDeviceSettings)
      this.$emit('deviceSettings', this.createdDeviceSettings)
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
