import AbstractService from '@/services/abstractService'

class DevicesService extends AbstractService {
  endpoint = 'devices'

  put(id, data, params = null) {
    const formData = new FormData()
    for (const key in data) {
      if ((key === 'image') && data[key] === undefined) {
        continue
      } else if ((key === 'settings')) {
        for (const index in data[key]) {
          formData.append(`${key}[${index}][capacity]`, data[key][index].capacity)
          formData.append(`${key}[${index}][intensity_level]`, data[key][index].intensity_level)
          formData.append(`${key}[${index}][stop]`, data[key][index].stop)
          formData.append(`${key}[${index}][work]`, data[key][index].work)
          formData.append(`${key}[${index}][oil_consumption]`, data[key][index].oil_consumption)
        }
      } else if ((key === 'is_wireless') || (key === 'is_oil_consumable')) {
        const bool = data[key] === true ? 1 : 0
        formData.append(key, bool)
      } else if (data[key] === null) {
        continue
      } else {
        formData.append(key, data[key])
      }
    }
    return this.http.put(`${this.endpoint}/${id}`, formData, params)
  }

  post(data) {
    // define append form
    const formData = new FormData()
    for (const key in data) {
      if ((key === 'settings')) {
        for (const index in data[key]) {
          formData.append(`${key}[${index}][capacity]`, data[key][index].capacity)
          formData.append(`${key}[${index}][intensity_level]`, data[key][index].intensity_level)
          formData.append(`${key}[${index}][stop]`, data[key][index].stop)
          formData.append(`${key}[${index}][work]`, data[key][index].work)
          formData.append(`${key}[${index}][oil_consumption]`, data[key][index].oil_consumption)
        }
      }  else if ((key === 'is_wireless') || (key === 'is_oil_consumable')) {
        const bool = data[key] === true ? 1 : 0
        formData.append(key, bool)
      }

      else {
        formData.append(key, data[key])
      }
    }
    return this.http.post(this.endpoint, formData)
  }

  changeStatus(id, data, params = null) {
    // define append form
    const formData = new FormData()

    for (const key in data) {
      formData.append(key, data[key])
    }
    return this.http.put(`change-device-status/${id}`, formData, params)
  }
}

const Service = new DevicesService()

export default Service
